.main {
  @apply bg-right-top bg-no-repeat;
  background-image: url('/images/city-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-grow: 1;
  height: 100%;
}

.title {
  @apply font-black leading-none;
  font-size: 12rem;
}

.car {
  @apply max-w-lg;
  max-width: 100%;
  z-index: 0;
}
